<template>
  <div class="container w-full mx-auto mb-10 px-4 md:px-0">
    <ViewTitle :title="$t('cabinet.comparisons.title')" />

    <div>
      <div v-for="comparison in comparisons" :key="comparison.id">
        <ComparisonSnippet :comparison="comparison" />
      </div>
    </div>

    <div class="text-center py-16" v-if="comparisons.length <= 0">
      <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
      <p class="text-gray-400">
        {{ $t("cabinet.comparisons.no_comparisons") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ComparisonSnippet from "@/components/cabinet/ComparisonSnippet";
import { InboxIcon } from "vue-feather-icons";
import ViewTitle from "@/components/ViewTitle.vue";

export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  components: {
    ComparisonSnippet,
    InboxIcon,
    ViewTitle,
  },
  created() {
    this.getUserComparisons();
  },
  watch: {
    $route: ["getUserComparisons"],
  },
  computed: mapState({
    comparisons: (state) => state.cabinet.comparisons.list,
  }),
  methods: {
    getUserComparisons() {
      this.$store.dispatch("cabinet/comparisons/getUserComparisons");
    },
    removeUserComparison(id) {
      this.$store.dispatch("cabinet/comparisons/removeUserComparison", id);
    },
  },
};
</script>
