<template>
  <div
    class="p-5 mb-5 rounded bg-gray-50 border dark:border-gray-700 items-center"
    :class="{
      'md:flex': !vertical,
    }"
  >
    <div
      class="mr-5 mb-2"
      :class="{
        'md:mb-0': !vertical,
      }"
      v-for="site in comparison.sites"
      :key="site.id"
    >
      <div class="">
        <router-link
          :to="
            $i18nRoute({
              name: 'site',
              params: { domain: site.domain },
            })
          "
          class=""
        >
          <div v-if="site.icon" class="inline-block w-10 align-middle mr-2">
            <img
              :src="root + site.icon"
              class="max-w-full max-h-full border dark:border-gray-700 rounded"
            />
          </div>
          {{ site.domain }}
        </router-link>
      </div>
    </div>

    <div class="ml-auto flex items-center justify-between">
      <div class="text-gray-500 text-sm">
        {{ $d(new Date(comparison.created_at), "short") }}
      </div>

      <router-link
        :to="
          $i18nRoute({
            name: 'compare-sites',
            params: {
              sites: comparison.sites.map((site) => site.domain).join(','),
            },
          })
        "
        class="inline-block uppercase bg-white px-5 py-2 rounded hover:bg-blue-400 hover:text-white shadow text-sm ml-5"
      >
        {{ $t("cabinet.comparisons.compare_button") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  props: {
    vertical: Boolean,
    comparison: {
      type: Object,
    },
  },
};
</script>

