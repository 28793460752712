var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5 mb-5 rounded bg-gray-50 border dark:border-gray-700 items-center",class:{
    'md:flex': !_vm.vertical,
  }},[_vm._l((_vm.comparison.sites),function(site){return _c('div',{key:site.id,staticClass:"mr-5 mb-2",class:{
      'md:mb-0': !_vm.vertical,
    }},[_c('div',{},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
            name: 'site',
            params: { domain: site.domain },
          })}},[(site.icon)?_c('div',{staticClass:"inline-block w-10 align-middle mr-2"},[_c('img',{staticClass:"max-w-full max-h-full border dark:border-gray-700 rounded",attrs:{"src":_vm.root + site.icon}})]):_vm._e(),_vm._v(" "+_vm._s(site.domain)+" ")])],1)])}),_c('div',{staticClass:"ml-auto flex items-center justify-between"},[_c('div',{staticClass:"text-gray-500 text-sm"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.comparison.created_at), "short"))+" ")]),_c('router-link',{staticClass:"inline-block uppercase bg-white px-5 py-2 rounded hover:bg-blue-400 hover:text-white shadow text-sm ml-5",attrs:{"to":_vm.$i18nRoute({
          name: 'compare-sites',
          params: {
            sites: _vm.comparison.sites.map(function (site) { return site.domain; }).join(','),
          },
        })}},[_vm._v(" "+_vm._s(_vm.$t("cabinet.comparisons.compare_button"))+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }